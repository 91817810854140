import {getCartEndpointStatus} from '@oracle-cx-commerce/commerce-utils/selector';

export const getCustomRepository = state => state.customRepository || {};

export const getProducts = state => getCustomRepository(state).customProducts || [];

export const getTypeaheadProducts = state => getCustomRepository(state).customTypeaheadRecords || [];
export const getSearchedProducts = state => getCustomRepository(state).customSearchedProducts || [];
export const getPrice = state => getCustomRepository(state).customPriceRepository || {};
export const getCustomErrorPriceRangeNoResults = state =>
  getCustomRepository(state).customErrorPriceRangeNoResults || false;

export const getUserTokens = state => getCustomRepository(state).userCustomToken || {};

export const getIsOrderLoading = state => {
  const getCartEndpointInProgress = getCartEndpointStatus(state);

  return getCartEndpointInProgress !== 1 ? false : true;
};
export const getCustomOrder = state => getCustomRepository(state).order || {};

export const getCustomOrderPromotions = state => getCustomOrder(state).promotions || {};
export const getCustomOrderPrice = state => getCustomOrder(state).price || {};

export const getCustomOrderCart = state => getCustomOrder(state).cart || {};
export const getCustomOrderCartItems = state => getCustomOrderCart(state).items || [];
export const getCustomOrderHasOnlyGiftCard = state => getCustomOrderCart(state).hasOnlyGiftCards || false;

export const getCustomConfirmation = state => getCustomRepository(state)?.confirmation;
export const getCustomConfirmationOrder = state => getCustomConfirmation(state)?.order;
export const getCustomConfirmationOrderXShippingScheduleInformation = state =>
  getCustomConfirmationOrder(state)?.x_shipping_schedule_information;
