import {combineReducers, createReducer} from '@oracle-cx-commerce/store/utils';

export const RESET_CHECKOUT_STEPS = 'resetCheckoutSteps';
export const SET_CHECKOUT_STEP = 'setCheckoutStep';
export const SET_USER_INFO = 'setUserInfo';
export const RESET_USER_INFO = 'resetUserInfo';
export const SET_SHIPPING_NOTES = 'setShippingNotes';

const reducer = combineReducers({
  customRepository: createReducer({
    [SET_CHECKOUT_STEP]: (state, {payload = {}}) => {
      const {checkout = {}} = state;
      const {steps = {}} = checkout;

      return {...state, checkout: {...checkout, steps: {...steps, ...payload}}};
    },
    [RESET_CHECKOUT_STEPS]: state => {
      const {checkout = {}} = state;
      const steps = {
        userInfo: 'active',
        shipping: 'pending',
        payment: 'pending'
      };

      return {...state, checkout: {...checkout, steps}};
    },
    [SET_USER_INFO]: (state, {payload = {}}) => {
      const {checkout = {}} = state;
      const {info = {}} = payload;

      return {...state, checkout: {...checkout, userInfo: info}};
    },
    [RESET_USER_INFO]: (state, {payload = {}}) => {
      const {checkout = {}} = state;
      const {rut = ''} = payload;
      const userInfo = {
        rut,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      };

      return {...state, checkout: {...checkout, userInfo}};
    },
    [SET_SHIPPING_NOTES]: (state, {payload = {}}) => {
      const {checkout = {}} = state;
      const {notes = ''} = payload;

      return {...state, checkout: {...checkout, shippingNotes: notes}};
    }
  })
});

export default {
  reducer
};
