import {all, call, put, select} from 'redux-saga/effects';
import {combineReducers, createReducer, takeEvery, takeLatest} from '@oracle-cx-commerce/store/utils';
import {getCurrentSiteId} from '@oracle-cx-commerce/commerce-utils/selector';

import {getRequest} from '@banco-de-chile/travel/src/utils/restClient';
import {getProducts} from '@banco-de-chile/travel/src/ui/selectors/custom-repository';

export const INIT_REPOSITORY = 'initCustomProductRepository';
export const GET_CUSTOM_PRODUCTS = 'customGetProduct';
export const LIST_CUSTOM_PRODUCTS = 'customListProducts';
export const CUSTOM_PRODUCTS = 'customProducts';
export const LIST_CUSTOM_PRODUCTS_WITHOUT_CURRENT = 'customListProductsWithoutCurrent';
export const CUSTOM_PRODUCTS_WITHOUT_CURRENT = 'customProductsWithoutCurrent';
export const CUSTOM_LIST_PRODUCTS_PROPERTIES = 'customListProductsProperties';
export const CUSTOM_LIST_PRODUCTS_PROPERTIES_EVERY = 'customListProductsPropertiesEvery';

export function setProducts(state, {payload = {}}) {
  const {products = []} = payload;
  const {customProducts = []} = state;

  return {...state, customProducts: [...customProducts, ...products]};
}

function getNewProducts(ids = [], currentProducts = []) {
  const currentProductsIds = currentProducts.map(currentProduct => currentProduct.repositoryId);
  const newProducts = currentProductsIds.length ? ids.filter(id => !currentProductsIds.includes(id)) : ids;

  return newProducts.length ? newProducts.join(',') : null;
}

export function* customListProductsProperties({payload = {}}) {
  try {
    const {productIds, includeChildSKUsListingIds = true, withPrices = false, fields = null} = payload;

    if (productIds) {
      const params = {
        productIds: productIds.join(','),
        includeChildSKUsListingIds,
        withPrices
      };

      if (fields) {
        params.fields = fields;
      }

      const siteId = yield select(getCurrentSiteId);

      const response = yield getRequest(`/ccstore/v1/products`, {
        params,
        headers: {'X-CCSite': siteId}
      });

      const {data} = response;

      return data.items;
    }

    return [];
  } catch (error) {
    console.error(error);

    return [];
  }
}

export function* customListProductsWithoutCurrent({payload = {}}) {
  try {
    const {productIds, includeChildSKUsListingIds = true, withPrices = true, useAdminApi = false} = payload;
    let currentProducts = [];
    const newProducts = getNewProducts(productIds, []);
    const endpoint = !useAdminApi ? `/ccstore/v1/products` : `/ccstorex/custom/v1/occ-utils/products`;

    if (newProducts) {
      const params = {
        productIds: newProducts,
        includeChildSKUsListingIds,
        withPrices
      };

      const siteId = yield select(getCurrentSiteId);

      const response = yield getRequest(endpoint, {
        params,
        headers: {'X-CCSite': siteId}
      });

      const {data} = response;

      yield put({
        type: CUSTOM_PRODUCTS_WITHOUT_CURRENT,
        payload: {
          products: data.items
        }
      });

      currentProducts = data.items;
    }

    return currentProducts;
  } catch (error) {
    console.error(error);

    return [];
  }
}

export function* customListProducts({payload = {}}) {
  try {
    const {productIds, includeChildSKUsListingIds = true, withPrices = true} = payload;
    let currentProducts = yield select(getProducts);
    const newProducts = getNewProducts(productIds, currentProducts);

    if (newProducts) {
      const params = {
        productIds: newProducts,
        includeChildSKUsListingIds,
        withPrices,
        search: 'active eq true'
      };

      const siteId = yield select(getCurrentSiteId);

      const response = yield getRequest(`/ccstore/v1/products`, {
        params,
        headers: {'X-CCSite': siteId}
      });

      const {data} = response;

      yield put({
        type: CUSTOM_PRODUCTS,
        payload: {
          products: data.items
        }
      });

      currentProducts = yield select(getProducts);
    }

    currentProducts = productIds.map(productId => {
      // sometimes, for some reason, the "id" prop is not returning from
      // the listProducts endpoint, so we're fallbacking to repositoryId
      const product = currentProducts.find(({id, repositoryId}) => (id || repositoryId) === productId);

      return product || {};
    });

    return currentProducts;
  } catch (error) {
    console.error(error);

    return [];
  }
}

export function* customGetProduct({payload = {}}) {
  try {
    const {productId} = payload || {};

    const data = {
      productIds: [productId]
    };

    const productList = yield call(customListProducts, {
      payload: data
    });

    const response = productList.filter(product => product.repositoryId === productId)[0];

    return response || null;
  } catch (error) {
    console.error(error);
  }
}

export function* initCustomRepository() {
  try {
    yield put({
      type: CUSTOM_PRODUCTS,
      payload: {
        products: []
      }
    });
  } catch (error) {
    console.error(error);
  }
}

const reducer = combineReducers({
  customRepository: createReducer({
    [CUSTOM_PRODUCTS]: setProducts
  })
});

function* saga() {
  yield all([
    takeEvery(GET_CUSTOM_PRODUCTS, customGetProduct),
    takeEvery(LIST_CUSTOM_PRODUCTS, customListProducts),
    takeEvery(INIT_REPOSITORY, initCustomRepository),
    takeEvery(LIST_CUSTOM_PRODUCTS_WITHOUT_CURRENT, customListProductsWithoutCurrent),
    takeLatest(CUSTOM_LIST_PRODUCTS_PROPERTIES, customListProductsProperties),
    takeEvery(CUSTOM_LIST_PRODUCTS_PROPERTIES_EVERY, customListProductsProperties)
  ]);
}

export default {
  reducer,
  saga
};
