export const setSpinnerVisible = () => import('./page');

export const setMinicartVisible = () => import('./header');

export const setShippingNotes = () => import('./checkout');
export const resetCheckoutSteps = () => import('./checkout');
export const setCheckoutStep = () => import('./checkout');
export const setUserInfo = () => import('./checkout');
export const resetUserInfo = () => import('./checkout');
export const oboOrderSubmit = () => import('./obo');

export const customModalActionOpen = () => import('./modal');
export const customModalActionClose = () => import('./modal');
export const serverLoggerAction = () => import('./server-logger');

export const customAddressActionClear = () => import('./address');
export const customAddressActionSet = () => import('./address');

export const customHeaderLocationActionClear = () => import('./header-location');
export const customHeaderLocationActionSet = () => import('./header-location');
